export default (locale, dateStr) => {
  if (!locale || locale.length === 0 || !dateStr || dateStr.length === 0) {
    throw new Error('dateFormat: invalid locale or date: ' + locale + ' ' + dateStr);
  }

  return (new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })).format(Date.parse(dateStr));
}
