import React from "react"
import {graphql} from "gatsby"

import Layout from '../layouts/default'

import Button from '../components/button'
import ContentElements from '../components/content-elements'
import Header from '../components/header'

import ImageHeader from "../content-elements/image-header";
import MediaText from '../content-elements/media-text'

import dateFormat from '../lib/date-format'

import styles from './single-advisor/component.module.less'
import StructuredData from "../components/structured-data";

export default ({data, pageContext}) => {

    const locale = pageContext.locale || 'de';

    const config = data.allDatoCmsConfig.nodes[0];
    const contact = data.datoCmsRatgeber.contact || config.contact;
    const contactUrl = config.pageAppointment.slug || config.pageContact.slug || null;

    const teaser = data.datoCmsRatgeber.content.find(f => f.__typename === "DatoCmsAdvisorTeaser");

    return (
        <>
            <Layout
                className={styles.layout}
                locale={pageContext.locale}
                localeLinks={pageContext.localeLinks}
                seo={pageContext.seoMetaTags}
                robots={pageContext.seoMetaRobots}
                canonical={pageContext.seoMetaCanonical}
            >
                {{
                    header: (
                        <Header
                            locale={pageContext.locale}
                            localeLinks={pageContext.localeLinks}
                            transparent={true}
                        >
                            <ImageHeader
                                containerClassName={styles.header}
                                image={data.datoCmsRatgeber.image}
                                title={pageContext.templateStrings.advisorPageTitleLabel}
                                headline={data.datoCmsRatgeber.title}
                                centerText={false}
                            >
                                {{
                                    before: (
                                        <div className={styles.back} data-datocms-noindex={true}>
                                            <a href={'/' + (pageContext.listUrlPath ? pageContext.listUrlPath : '')}>
                                                {pageContext.templateStrings.advisorPageBackButtonLabel}
                                            </a>
                                        </div>
                                    ),
                                    afterText: (
                                        <>
                                            <div className={styles.details}>
                                                {data.datoCmsRatgeber.author &&
                                                    <div className={styles.author}>
                                                        {data.datoCmsTemplateTexte.advisorPageAuthorLabel}: {data.datoCmsRatgeber.author}
                                                        <br/>
                                                    </div>
                                                }

                                                <div className={styles.date}>
                                                    {data.datoCmsTemplateTexte.advisorPageDateLabel}: {dateFormat(locale, data.datoCmsRatgeber.publicationDate)}
                                                </div>

                                                {data.datoCmsRatgeber.readingTime > 0 && (
                                                    <div
                                                        className={styles.readingTime}>{data.datoCmsTemplateTexte.advisorPageReadingTimeLabel}:&nbsp;{data.datoCmsRatgeber.readingTime}&nbsp;Minuten</div>
                                                )}
                                            </div>

                                            {data.datoCmsRatgeber.intro &&
                                                <div className={styles.intro}
                                                     dangerouslySetInnerHTML={{__html: data.datoCmsRatgeber.intro}}/>
                                            }
                                        </>
                                    ),
                                }}
                            </ImageHeader>
                        </Header>
                    ),
                    content: (
                        <>
                            <div className={styles.article}>
                                <ContentElements
                                    locale={locale}
                                    items={data.datoCmsRatgeber.content.filter(f => f.__typename !== "DatoCmsAdvisorTeaser")}
                                    config={config}
                                    classNames={{
                                        'DatoCmsSimpletext': () => styles.slim,
                                        'DatoCmsQuote': () => styles.slim,
                                        'DatoCmsSimpleimage': ({
                                                                   layoutReduced,
                                                                   layoutRaise
                                                               }) => `${layoutReduced === true ? styles.hugs : undefined} ${layoutRaise === true ? styles.raisedImage : undefined}`,
                                        'DatoCmsVideo': ({layoutRaise}) => layoutRaise === true ? styles.raised : undefined,
                                    }}
                                />
                            </div>

                            {contact &&
                                <MediaText
                                    className={styles.contact}
                                    title={contact.teaserTitle}
                                    htmlText={contact.teaserText}
                                    image={contact.image}
                                    image2={contact.teaserAside}
                                    reverse={true}
                                    childPosition="text"
                                >
                                    {contactUrl &&
                                        <Button
                                            href={'/' + locale + '/' + contactUrl + '/'}
                                            theme="block"
                                        >
                                            {pageContext.templateStrings.advisorPageAppointmentButtonLabel}
                                        </Button>
                                    }
                                </MediaText>
                            }

                            {teaser && (
                                <ContentElements
                                    locale={locale}
                                    items={[teaser]}
                                    config={config}
                                />
                            )}
                        </>
                    )
                }}
            </Layout>
            <StructuredData
                type="BlogPosting"
                {...{
                    "headline":data.datoCmsRatgeber.title,
                    "image": data.datoCmsRatgeber.image.fluid.src ,
                    "datePublished": data.datoCmsRatgeber.meta.firstPublishedAt,
                    "dateModified": data.datoCmsRatgeber.meta.updatedAt,
                    "author": data.datoCmsRatgeber.author2.map(item => ({
                        "@type": item.authorType,
                        "name":  item.name,
                        "jobTitle": item.jobtitle,
                        "url": item.url
                        })
                    )
                }}
            />
        </>
    )
}

export const query = graphql`
    query($locale: String!, $id: String!) {
  
        datoCmsRatgeber(id: {eq: $id }) {
            ...advisor_content
        
            id
            publicationDate
            author
            title
            intro
            readingTime
            meta {
                updatedAt
                firstPublishedAt
            }
            author2 {
                authorType
                name
                jobtitle
                url
            }
          
            image {
                alt
                fluid(imgixParams: {w: "890", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}) {
                    src
                }
            }
          
            contact {
                ...contact_full
            }
        }
        
        datoCmsTemplateTexte(locale: {eq: $locale}) {
            advisorPageDateLabel
            advisorPageAuthorLabel
            advisorPageReadingTimeLabel
        }
        
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                ...config_data
            }
        }
    
    }
`
